export type AnimationData = {
  layers: Array<{
    shapes: Array<{ it: Array<{ c: { k: Array<number> } }> }>;
  }>;
};

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};
export const changeColorInAnimation = (
  animationData: AnimationData,
  hexColor: string,
) => {
  const rgbColor = hexToRgb(hexColor);

  animationData.layers.forEach(layer => {
    layer.shapes.forEach(shape => {
      shape.it.forEach(item => {
        if (item.c) {
          // Change color to new RGB values
          item.c.k = [rgbColor.r / 255, rgbColor.g / 255, rgbColor.b / 255];
        }
      });
    });
  });

  return animationData;
};
