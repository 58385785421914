import React from 'react';
import animationData from './long-audio-wave.json';
import { AnimationData, changeColorInAnimation } from '../setLottieColor';
import { LottieComponentProps } from '../type';
import { useLottie } from '../useLottie';

export const LongAudioWave = ({
  isPlaying,
  style,
  className,
  ...props
}: LottieComponentProps) => {
  const { lottieContainer } = useLottie(
    {
      ...props,
      animationData: changeColorInAnimation(
        animationData as AnimationData,
        props.color || '#000000',
      ),
    },
    isPlaying,
  );

  return <div ref={lottieContainer} style={style} className={className} />;
};
