import { Channel } from '@/lib/model';
import { generateChannelPath } from '@/utils';

export function getArticleLink(channel: Channel, articleSlug: string) {
  return `/${generateChannelPath(channel)}/article/${articleSlug}`;
}

export function getVideoLink(channel: Channel, videoSlug: string) {
  return `/${generateChannelPath(channel)}/video/${videoSlug}`;
}

export function getAudioLink(channel: Channel, audioSlug: string) {
  return `/${generateChannelPath(channel)}/audio/${audioSlug}`;
}

export function getLiveLink(channel: Channel, slug: string) {
  return `/${generateChannelPath(channel)}/live/${slug}`;
}

export function getTVShowLink(channel: Channel, slug: string) {
  return `/${generateChannelPath(channel)}/tv-show/${slug}`;
}
