'use client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FullScreenIcon,
  MuteVolumeIcon,
  PauseIcon,
  PlayIcon,
  UnMuteVolumeIcon,
} from '@/app/components/icons';
import Player from 'video.js/dist/types/player';
import LiveTvVideoPlayer from '../../media/LiveTvVideoPlayer';
import { Program } from '@/lib/model/program';
import { LiveStream } from '@/lib/model';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { generateChannelPath } from '@/utils';
import { useTranslations } from 'next-intl';
import { internetAvailableForVideo } from '@/common/utils';
import videojs from 'video.js';
import clsx from 'clsx';
import { LongAudioWave } from '@/app/components/animations/audio-wave/LongAudioWave';
import { EpgRadio } from '@/lib/model/epg-radio';
import { cn } from '@/lib/utils';

const defaultOptions = {
  controls: false,
  fluid: true,
  autoplay:
    typeof window !== 'undefined' && internetAvailableForVideo()
      ? window.innerWidth >= 768
      : false,
  bigPlayButton: false,
  muted: true,
  preload: 'none',
  controlBar: {
    pictureInPictureToggle: false,
  },
} as typeof videojs.options;

export function LiveTvWrapper({ className }: { className?: string }) {
  const [isMuted, setIsMuted] = useState(true);
  const t = useTranslations();
  const [isPaused, setIsPaused] = useState(!defaultOptions.autoplay);
  const playerRef = useRef<Player | null>(null);
  const channel = useGetChannelPref();
  const [program, setProgram] = useState<Program | undefined>(undefined);
  const [epgRadio, setEpgRadio] = useState<EpgRadio | undefined>(undefined);
  const [ready, setReady] = useState<boolean>();

  const liveStream = useMemo(() => {
    return channel?.streamingOptions?.liveStream as LiveStream | undefined;
  }, [channel?.streamingOptions?.liveStream]);

  const getLiveRadioStation = useCallback(async () => {
    if (liveStream?.epgUrl) {
      const response = await fetch(liveStream?.epgUrl);
      if (!response.ok) {
        setEpgRadio(undefined);
        return;
      }
      const data: EpgRadio = await response.json();
      setEpgRadio(data);
    } else {
      setEpgRadio(undefined);
    }
  }, [liveStream?.epgUrl]);

  const getLiveTvProgram = useCallback(async () => {
    const response = await fetch(`/api/live-tv?channelId=${channel?.id}`);
    if (!response.ok) {
      setProgram(undefined);
      return;
    }
    const data: Program = await response.json();
    setProgram(data);
  }, [channel?.id]);

  const handlePlayerReady = useCallback(
    (player: Player) => {
      playerRef.current = player;
      setReady(true);
    },
    [playerRef],
  );

  const onClickPlayPauseIcon = () => {
    if (playerRef.current) {
      setIsPaused(paused => {
        if (paused) {
          playerRef.current?.play()?.catch(() => {
            // on video.js play error due to element unmount or pause before play
          });
        } else {
          playerRef.current?.pause();
        }
        return !paused;
      });
    }
  };

  const onClickMuteUnMuteIcon = () => {
    if (playerRef.current) {
      setIsMuted(muted => {
        playerRef.current?.muted(!muted);
        return !muted;
      });
    }
  };
  const volumeChange = useCallback(() => {
    if (
      (playerRef.current?.el() as HTMLElement).querySelector('video')?.muted
    ) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  }, [playerRef]);
  const playHandler = useCallback(() => {
    setIsPaused(false);
    volumeChange();
  }, [volumeChange]);
  const pauseHandler = useCallback(() => {
    setIsPaused(true);
    volumeChange();
  }, [volumeChange]);

  const options = useMemo(() => {
    return {
      ...defaultOptions,
      sources: [
        {
          src: liveStream?.streamUrl,
          type: liveStream?.type === 'tv' ? 'application/x-mpegURL' : undefined,
        },
      ],
      muted: true,

      poster: '/png/radio-placeholder-small.png',
      audioOnlyMode: liveStream?.type === 'radio',
      audioPosterMode: liveStream?.type === 'radio',
      fullScreen: liveStream?.type === 'tv',
    } as typeof defaultOptions;
  }, [liveStream]);
  useEffect(() => {
    if (ready) {
      const player = playerRef.current;
      player?.on('play', playHandler);
      player?.on('pause', pauseHandler);
      return () => {
        player?.off('play', playHandler);
        player?.off('pause', pauseHandler);
      };
    }
  }, [pauseHandler, playHandler, ready, volumeChange]);

  useEffect(() => {
    if (liveStream?.type === 'radio') {
      getLiveRadioStation().then();
    } else {
      getLiveTvProgram().then();
    }
  }, [getLiveRadioStation, getLiveTvProgram, liveStream?.type]);

  useEffect(() => {
    if (liveStream?.type === 'tv' && program) {
      const endTime = new Date(
        new Date(program.planDateTime).getTime() +
          program.planDurationMilisecond,
      );
      const now = new Date();
      const timeUntilEnd = endTime.getTime() - now.getTime();
      if (timeUntilEnd > 0) {
        const timer = setTimeout(() => {
          getLiveTvProgram().then();
        }, timeUntilEnd);

        return () => clearTimeout(timer);
      }
    }
    if (liveStream?.type === 'radio' && epgRadio?.now_playing.remaining) {
      const timeUntilEnd = epgRadio.now_playing.remaining * 1000;
      if (timeUntilEnd > 0) {
        const timer = setTimeout(() => {
          getLiveRadioStation().then();
        }, timeUntilEnd);
        return () => clearTimeout(timer);
      }
    }
  }, [
    program,
    getLiveTvProgram,
    liveStream?.type,
    epgRadio,
    getLiveRadioStation,
  ]);

  if (!program && !epgRadio) return null;
  if (!channel?.streamingOptions?.active) return null;

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="bg-grey-3 rounded-t-custom w-full p-2">
        <div className="flex justify-between items-center sm:pr-4 rtl:sm:pl-4">
          <div className="flex flex-row sm:gap-3 gap-2">
            <div
              className={
                'relative cursor-pointer sm:w-[114px] sm:h-16 w-[86px] h-12'
              }
            >
              <LiveTvVideoPlayer
                options={options}
                onReady={handlePlayerReady}
                title={
                  program?.programName ??
                  epgRadio?.now_playing.song?.custom_fields.title ??
                  channel.name
                }
                description={
                  program?.synopsis ??
                  epgRadio?.now_playing.song?.custom_fields.description ??
                  channel.description
                }
                showMiniPlayer={true}
                liveStream={liveStream}
                redirectUrl={`/${generateChannelPath(channel)}/live`}
              />
              {liveStream?.type === 'radio' && (
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[2]">
                  <LongAudioWave color="#FFFFFF" isPlaying={!isPaused} />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <div className="flex sm:flex-row gap-2 sm:items-center flex-col">
                <div
                  className={clsx(
                    'rounded gap-2 px-2 py-1 flex flex-row items-center w-fit',
                    {
                      'bg-categories-live': liveStream?.type === 'tv',
                      'bg-categories-opinion': liveStream?.type === 'radio',
                    },
                  )}
                >
                  <div className="w-3 h-3 rounded-full bg-white" />
                  <div className="text-grey-1 font-sf text-xxs2 font-bold uppercase leading-normal tracking-[0.55px]">
                    {t(liveStream?.type === 'tv' ? 'liveTV' : 'liveRadio')}
                  </div>
                </div>
                <div className="sm:block hidden text-grey-11 w-32 sm:w-2/3 truncate overflow-ellipsis font-notoserif text-lg font-medium leading-normal max-w-xl">
                  {program?.programName ??
                    epgRadio?.now_playing.song?.custom_fields.title ??
                    liveStream?.title}
                </div>
              </div>
              <div className="sm:block w-80 md:w-128 hidden overflow-hidden text-grey-11 overflow-ellipsis font-notosans text-xs2 font-normal truncate max-w-xl leading-normal">
                {program?.synopsis ??
                  epgRadio?.now_playing.song?.custom_fields.description ??
                  liveStream?.description}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Icons
              onClickMuteUnMuteIcon={onClickMuteUnMuteIcon}
              onClickPlayPauseIcon={onClickPlayPauseIcon}
              isPaused={isPaused}
              isMuted={isMuted}
              isFullScreen={liveStream?.type === 'tv'}
              player={playerRef.current}
            />
          </div>
        </div>
        <div className="sm:hidden block w-full h-[1px] bg-grey-6 my-2" />
        <div className="sm:hidden flex text-grey-11 font-notoserif text-lg leading-normal font-medium">
          {program?.programName ??
            epgRadio?.now_playing.song?.custom_fields.title ??
            channel.name}
        </div>
      </div>
      <div
        className={clsx('w-full h-1 rounded-b-custom', {
          'bg-categories-live': liveStream?.type === 'tv',
          'bg-categories-opinion': liveStream?.type === 'radio',
        })}
      />
    </div>
  );
}

type IconsProps = {
  player?: Player | null;
  isMuted?: boolean;
  isPaused?: boolean;
  isFullScreen?: boolean;
  onClickMuteUnMuteIcon?: () => void;
  onClickPlayPauseIcon?: () => void;
};
const Icons = ({
  isMuted,
  isPaused,
  isFullScreen,
  onClickMuteUnMuteIcon,
  onClickPlayPauseIcon,
  player,
}: IconsProps) => {
  return (
    <>
      {isMuted ? (
        <UnMuteVolumeIcon
          onClick={onClickMuteUnMuteIcon}
          className="cursor-pointer"
        />
      ) : (
        <MuteVolumeIcon
          onClick={onClickMuteUnMuteIcon}
          className="cursor-pointer"
        />
      )}
      {isPaused ? (
        <PlayIcon onClick={onClickPlayPauseIcon} className="cursor-pointer" />
      ) : (
        <PauseIcon onClick={onClickPlayPauseIcon} className="cursor-pointer" />
      )}
      {isFullScreen && (
        <FullScreenIcon
          className={'cursor-pointer'}
          onClick={() => {
            const fullScreenChange = () => {
              const isFullscreen = player?.isFullscreen();
              if (isFullscreen) {
                player?.controls(true);
              } else {
                player?.controls(false);
                player?.off('fullscreenchange', fullScreenChange);
              }
            };
            player?.requestFullscreen();
            player?.on('fullscreenchange', fullScreenChange);
          }}
        />
      )}
    </>
  );
};
