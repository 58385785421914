import { useEffect, useRef } from 'react';
import { lottieLoader } from './lottieLoader';

export function useLottie(props: any, isPlaying?: boolean) {
  const ref = useRef<any>(null);

  const lottieContainer = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<any>(null);
  useEffect(() => {
    if (isPlaying) {
      animationRef.current?.play?.();
    } else {
      animationRef.current?.pause?.();
    }
  }, [isPlaying]);
  const loaded = useRef(false);
  useEffect(() => {
    function onLottieLoad() {
      // @ts-ignore
      if (!loaded.current && window.lottie && lottieContainer.current) {
        loaded.current = true;
        lottieContainer.current.innerHTML = '';
        // @ts-ignore
        animationRef.current = window.lottie.loadAnimation({
          container: lottieContainer.current,
          renderer: 'svg',
          loop: true,
          ...props,
        });
        ref.current = animationRef.current;
      }
    }
    lottieLoader(onLottieLoad);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        animationRef.current.destroy?.();
      }
    };
  }, []);
  return {
    lottieContainer,
  };
}
