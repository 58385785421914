import { cn } from '@/lib/utils';
import { CarouselItem } from '.';
import { useDeviceType } from '@/hooks/useIsDesktop';

export const CarouselLastElementPadding = ({
  contentLength,
  index,
  className,
}: {
  contentLength: number;
  index: number;
  className?: string;
}) => {
  if (index === contentLength - 1) {
    return <CarouselItem className={cn('block md:hidden', className)} />;
  }
  return null;
};

export const CarouselEndPadding = ({ className }: { className?: string }) => {
  const { isMobile } = useDeviceType();
  if (isMobile) {
    return <CarouselItem className={cn('block sm:hidden', className)} />;
  }
};
