import React, { forwardRef } from 'react';

import { cn } from '@/lib/utils';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren, HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

export const IconButtonWrapper = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn('w-7 h-7 bg-[#FFFFFF4D] rounded-full', className)}
        {...props}
      >
        {children}
      </button>
    );
  },
);
