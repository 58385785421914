import { SelectedMedia, Resolution } from '@/lib/model';

const RESOULTION_ORDERS: Record<Resolution, number> = {
  '360p': 0,
  '480p': 1,
  '720p': 2,
  '1080p': 3,
};

export function getVidoeSrc(
  media: SelectedMedia,
  resolution: Resolution = '480p',
) {
  let src = media?.media?.url || '';

  if (!src) return '';

  if (media.media?.playlistJson) {
    const items = media.media.playlistJson.items
      ?.map(item => ({
        ...item,
        distance:
          item.label in RESOULTION_ORDERS
            ? Math.abs(
                RESOULTION_ORDERS[resolution] - RESOULTION_ORDERS[item.label],
              )
            : Infinity,
      }))
      ?.sort((a, b) => a.distance - b.distance);

    const selectedResolution = items?.[0];

    if (selectedResolution) {
      src = selectedResolution.src;
    }
  }
  return src;
}
