const LOTTIE_CDN =
  'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.12.0/lottie.min.js';

const LOTTIE_SCRIPT_ID = 'LOTTIE_SCRIPT-ID';

export function lottieLoader(onLottieLoad: () => void) {
  if (typeof document === 'undefined') return;
  if (document.getElementById(LOTTIE_SCRIPT_ID)) {
    onLottieLoad();
    return;
  }
  const script = document.createElement('script');
  script.src = LOTTIE_CDN;
  script.async = true;
  script.defer = true;
  script.id = LOTTIE_SCRIPT_ID;

  script.onload = () => {
    onLottieLoad();
  };

  document.head.appendChild(script);
}
