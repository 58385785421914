'use client';

import { cn } from '@/lib/utils';
import Image, { ImageProps } from 'next/image';
import { mediaShimmer, toBase64 } from './mediaShimmer';
import { HeadphoneIcon, PlayIcon } from '@/app/components/icons';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useState,
} from 'react';
import { SelectedMedia } from '@/lib/model';
import { getOptimizedImageFromCoverImage } from '@/common/utils';

export interface VideoPlayerPlaceholderRef {
  setShowImagePoster: Dispatch<SetStateAction<boolean>>;
  setShowInitialPlayButton: Dispatch<SetStateAction<boolean>>;
}

interface Props extends Pick<ImageProps, 'width' | 'height' | 'quality'> {
  posterPriority?: boolean;
  onPlay: () => void;
  duration?: string;
  media?: SelectedMedia;
  isAudio?: boolean;
  isInitiated?: boolean;
}

export const VideoPlayerPlaceholder = forwardRef<
  VideoPlayerPlaceholderRef,
  Props
>(
  (
    {
      duration,
      width,
      height,
      posterPriority,
      onPlay,
      isAudio,
      media,
      quality,
      isInitiated,
    },
    ref,
  ) => {
    const [showImagePoster, setShowImagePoster] = useState(true);
    const [showInitialPlayButton, setShowInitialPlayButton] = useState(true);
    const image = getOptimizedImageFromCoverImage(
      media,
      width as number,
      height as number,
    );
    useImperativeHandle(ref, () => ({
      setShowImagePoster,
      setShowInitialPlayButton,
    }));
    return (
      <div
        className={cn('absolute overflow-hidden z-10 top-0 w-full h-full', {
          'absolute opacity-0 z-0 w-0 h-0': !showImagePoster || isInitiated,
        })}
        onClick={onPlay}
      >
        <Image
          fill
          className="object-cover"
          src={image || ''}
          alt={media?.media?.caption || ''}
          placeholder={`data:image/svg+xml;base64,${toBase64(mediaShimmer({ width, height }))}`}
          priority={posterPriority}
          quality={quality || 80}
          onError={() => {
            setShowImagePoster(false);
          }}
        />
        {showInitialPlayButton ? (
          <>
            <div
              onClick={onPlay}
              className="w-full h-[50%] z-1 absolute bottom-0 bg-gradient-to-b -from-[0%] to-[100%] from-[#00000000] to-[#000000E5] rounded-custom"
            />
            <div
              onClick={onPlay}
              className={'absolute z-1 left-4 bottom-4 flex flex-row gap-2'}
            >
              <div className="w-7 h-7 bg-[#FFFFFF4D] rounded-full flex flex-col items-center justify-center text-white cursor-pointer">
                {isAudio ? <HeadphoneIcon /> : <PlayIcon />}
              </div>
              {duration && (
                <div className="font-sf text-sm font-medium text-grey-2 uppercase my-auto duration-text">
                  {duration}
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    );
  },
);
