'use client';

import { useGetChannelPref } from '@/providers/ChannelProvider';
import Link from 'next/link';
import { ComponentProps } from 'react';
import { getArticleLink } from './link-utils';

interface Props extends Omit<ComponentProps<typeof Link>, 'href'> {
  articleSlug?: string | null;
}

const ArticleClientLink = ({ articleSlug: articleId, ...props }: Props) => {
  const channelPref = useGetChannelPref();
  if (!articleId) {
    return (
      <div style={props.style} className={props.className}>
        {props.children}
      </div>
    );
  }
  return (
    <Link {...props} href={getArticleLink(channelPref, articleId as string)} />
  );
};

export const ArticleLink = (props: Props) => {
  return <ArticleClientLink {...props} />;
};
