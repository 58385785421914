'use client';

import { useEffect, useMemo, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Player from 'video.js/dist/types/player';
import './video.css';
import clsx from 'clsx';
import {
  VideoPlayerPlaceholder,
  VideoPlayerPlaceholderRef,
} from './VideoPlayerPlaceholder';
import { LiveStream, SelectedMedia } from '@/lib/model';

type PlayerOptions = typeof videojs.options;

type Props = {
  options: PlayerOptions;
  onReady?: (player: Player) => void;
  title?: string;
  description?: string | null;
  redirectUrl?: string;
  showMiniPlayer?: boolean;
  liveStream?: LiveStream;
  width?: number;
  height?: number;
  media?: SelectedMedia;
};

export const LiveTvVideoPlayer = ({
  options,
  onReady,
  title,
  description,
  redirectUrl,
  showMiniPlayer,
  liveStream,
  width,
  height,
  media,
}: Props) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const videoPlayerPlaceholderRef = useRef<VideoPlayerPlaceholderRef | null>(
    null,
  );
  const aspectRatio = useMemo(
    () => (width && height ? `${width} / ${height}` : ''),
    [width, height],
  );
  useEffect(() => {
    videoPlayerPlaceholderRef.current?.setShowInitialPlayButton(false);
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      if (aspectRatio) {
        videoElement.classList.add('h-full');
      }
      videoRef?.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        if (onReady) {
          onReady(player);
        }
        const dataset =
          (player.el().closest('.video-container') as HTMLDivElement)
            ?.dataset || {};
        if (liveStream?.type === 'tv') {
          dataset.isLive = String('true');
        } else if (liveStream?.type === 'radio') {
          dataset.isLiveRadio = String('true');
        }
        dataset.title = title;
        dataset.description = description ?? '';
        dataset.options = JSON.stringify(options);
        dataset.redirectUrl = redirectUrl;
        player.on('play', () => {
          videoPlayerPlaceholderRef.current?.setShowImagePoster(false);
        });
      }));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef, onReady, title, description, redirectUrl, liveStream]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div
      className={clsx('relative video-container', {
        'show-mini-player': showMiniPlayer && liveStream?.type === 'tv',
      })}
      data-vjs-player
      {...(aspectRatio && { style: { aspectRatio } })}
    >
      <div className="rounded-custom h-full" ref={videoRef} />
      {media ? (
        <VideoPlayerPlaceholder
          ref={videoPlayerPlaceholderRef}
          width={width}
          height={height}
          onPlay={() =>
            playerRef.current?.play()?.catch(() => {
              // on video.js play error due to element unmount or pause before play
            })
          }
          isAudio={false}
          media={media}
        />
      ) : null}
    </div>
  );
};

export default LiveTvVideoPlayer;
