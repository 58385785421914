'use client';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Player from 'video.js/dist/types/player';
import { ContentMetadata, SelectedMedia, Resolution } from '@/lib/model';
import './video.css';
import {
  getContentDurationString,
  getOptimizedImageFromCoverImage,
  internetAvailableForVideo,
} from '@/common/utils';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { generateChannelPath } from '@/utils';
import errorHandler from './videoPlayerErrorHandler';
import { cn } from '@/lib/utils';
import {
  VideoPlayerPlaceholder,
  VideoPlayerPlaceholderRef,
} from './VideoPlayerPlaceholder';
import { useDeviceType } from '@/hooks/useIsDesktop';
import { getVidoeSrc } from '@/common/getVideoSrc';

type PlayerOptions = typeof videojs.options;

type Props = {
  media?: SelectedMedia;
  onReady?: (player: Player) => void;
  isVideo?: boolean;
  isAudio?: boolean;
  isLite?: boolean;
  autoplay?: boolean;
  contentMetadata: ContentMetadata;
  showMiniPlayer?: boolean;
  [key: string]: any;
  posterPriority?: boolean;
  videoContainerClassName?: string;
  playerClassName?: string;
  resolution?: Resolution;
  viewInDetailPage?: boolean;
  hidePlaceholder?: boolean;
};

const initialOptions = {
  controls: true,
  fluid: true,
  autoplay: false,
  muted: false,
  bigPlayButton: false,
  preload: 'none',
  controlBar: {
    volumePanel: {
      inline: true,
    },
    pictureInPictureToggle: false,
  },
} as PlayerOptions;

const initialLiteOptions = {
  loop: true,
  controls: true,
  fluid: true,
  autoplay: false,
  muted: true,
  bigPlayButton: false,
  preload: 'none',
  controlBar: {
    volumePanel: false,
    muteToggle: true,
    pictureInPictureToggle: false,
    progressControl: false,
    fullscreenToggle: false,
    remainingTimeDisplay: false,
  },
} as PlayerOptions;

export const VideoPlayer = ({
  media,
  onReady,
  isVideo,
  isAudio,
  isLite = true,
  autoplay,
  contentMetadata,
  showMiniPlayer,
  posterPriority,
  videoContainerClassName,
  playerClassName = 'rounded-custom',
  quality = 80,
  resolution = '480p',
  viewInDetailPage,
  hidePlaceholder,
  ...props
}: Props) => {
  const { isMobile } = useDeviceType();
  const style = useMemo(() => {
    if (props.width && props.height)
      return {
        aspectRatio: `${props.width} / ${props.height}`,
        ...(props.style || {}),
      };

    return props.style || {};
  }, [props.height, props.width, props.style]);
  const videoRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<Player | null>(null);
  const videoPlayerPlaceholderRef = useRef<VideoPlayerPlaceholderRef | null>(
    null,
  );
  const [duration, setDuration] = useState(
    getContentDurationString(contentMetadata?.duration),
  );

  const [ready, setReady] = useState(false);
  const channelPref = useGetChannelPref();

  const options = useMemo(() => {
    if (!media) return;
    const withAutoPlay = internetAvailableForVideo() && autoplay && !isMobile;
    return {
      ...(isLite ? initialLiteOptions : initialOptions),
      autoplay: autoplay && withAutoPlay,
      playsinline: true,

      sources: [
        {
          type:
            ([
              'application/x-www-form-urlencoded',
              'application/octet-stream',
              'application/vnd.apple.mpegurl',
            ].includes(media?.media?.mimeType || '')
              ? 'video/mp4'
              : media?.media?.mimeType) ?? 'application/x-mpegURL',
          src: encodeURI(getVidoeSrc(media, resolution)),
        },
      ],
      poster: getOptimizedImageFromCoverImage(media, props.width, props.height),
      audioOnlyMode: isAudio,
      audioPosterMode: isAudio,
    } as PlayerOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, isAudio, isLite, media]);
  const onErrorHandler = useCallback(() => {
    videoPlayerPlaceholderRef.current?.setShowImagePoster(true);
    videoPlayerPlaceholderRef.current?.setShowInitialPlayButton(false);
  }, []);
  const createVideoJSContainer = useCallback(() => {
    const videoElement = document.createElement('video-js');
    videoElement.style.height = '100%';
    videoRef?.current?.appendChild(videoElement);
    const player = (playerRef.current = videojs(videoElement, options, () => {
      if (onReady) {
        onReady(player);
      }
      setReady(true);
      const dataset =
        (player.el().closest('.video-container') as HTMLDivElement)?.dataset ||
        {};
      dataset.isLite = String(isLite);
      dataset.isVideo = String(isVideo);
      dataset.isAudio = String(isAudio);
      dataset.title = contentMetadata.title;
      dataset.description = contentMetadata.description ?? '';
      dataset.redirectUrl = `/${generateChannelPath(channelPref)}${props.href}`;
      dataset.options = JSON.stringify(options);
      player.on('play', () => {
        videoPlayerPlaceholderRef.current?.setShowImagePoster(false);
      });

      player.on('loadedmetadata', () => {
        if (!getContentDurationString(contentMetadata.duration)) {
          setDuration(getContentDurationString(player?.duration()));
        }
      });
      errorHandler(player, {});
      player.on(['aderror', 'contenterror', 'error'], () => onErrorHandler());
    }));
  }, [
    contentMetadata.description,
    contentMetadata.duration,
    contentMetadata.title,
    isAudio,
    isLite,
    isVideo,
    onReady,
    options,
    props.href,
    channelPref,
    onErrorHandler,
  ]);

  useEffect(() => {
    if (!playerRef.current) {
      if (!options) return;
      createVideoJSContainer();
    } else {
      const withAutoPlay = internetAvailableForVideo() && !isMobile;

      const player = playerRef.current;
      player.autoplay(withAutoPlay ? false : options.autoplay);
      player.src(options.sources);
    }
  }, [options, createVideoJSContainer, isMobile]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.off(['aderror', 'contenterror', 'error'], () =>
          onErrorHandler(),
        );
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [onErrorHandler]);
  return (
    <div
      className={cn('relative video-container w-full h-full', {
        'lite-video-container': isLite,
        'show-mini-player': showMiniPlayer,
        'pointer-events-none': viewInDetailPage,
        videoContainerClassName,
      })}
      data-vjs-player
      style={style}
    >
      <div
        className={cn('h-full', playerClassName, {
          'absolute opacity-0': !ready,
        })}
        ref={videoRef}
      />

      {hidePlaceholder ? null : (
        <VideoPlayerPlaceholder
          ref={videoPlayerPlaceholderRef}
          duration={duration}
          width={props.width}
          height={props.height}
          posterPriority={posterPriority}
          onPlay={() => {
            if (!viewInDetailPage) {
              playerRef.current?.play()?.catch(() => {
                // on video.js play error due to element unmount or pause before play
              });
            }
          }}
          isAudio={isAudio}
          media={media}
          quality={quality}
        />
      )}
    </div>
  );
};
