export const mediaShimmer = ({
  width,
  height,
  colors = ['#E9E9E9', '#DDD'],
  className,
}: {
  width: any;
  height: any;
  colors?: [string, string];
  className?: string;
}) => `
<svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="${className}">
  <defs>
    <linearGradient id="g">
      <stop stop-color="${colors[0]}" offset="0%" />
      <stop stop-color="${colors[1]}" offset="50%" />
      <stop stop-color="${colors[0]}" offset="100%" />
    </linearGradient>
  </defs>
  <rect width="${width}" height="${height}" fill="${colors[0]}"/>
  <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${width}" to="${width}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);
